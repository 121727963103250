import { combineReducers } from "@reduxjs/toolkit";
import propertyReducer from "~/stores/slices/property";
import headerReducer from "~/stores/slices/header";

const rootReducer = combineReducers({
  property: propertyReducer,
  header: headerReducer,
});

export default rootReducer;
