import {lazy} from "react";

const CheckPass = lazy(() => import("~/pages/Password/CheckPass"));
const ChangePass = lazy(() => import("~/pages/Password/ChangePass"));

const password = [
  {
    path: "check-pass",
    element: <CheckPass />,
  },
  {
    path: "change-pass",
    element: <ChangePass />,
  },
]

export default password;
