import { useState, createContext } from "react";

const BrandContext = createContext({
  brands: [],
  cursor: 0,
  search: "",
  isLoading: true,
  isLoadingScroll: false,
  totalBrand: 0,
  selectBrand: [],
  setIsLoading: () => {},
  setBrand: () => {},
  setSearch: () => {},
  setCursor: () => {},
  setIsLoadingScroll: () => {},
  setTotalBrand: () => {},
  setSelectBrand: () => {},
});

const BrandContextProvider = ({ children }) => {
  const setBrand = (brands) => {
    setState((val) => ({ ...val, brands }));
  };
  const setIsLoading = (isLoading) => {
    setState((val) => ({ ...val, isLoading }));
  };
  const setSearch = (search) => {
    setState((val) => ({ ...val, search }));
  };
  const setCursor = (cursor) => {
    setState((val) => ({ ...val, cursor }));
  };
  const setIsLoadingScroll = (isLoadingScroll) => {
    setState((val) => ({ ...val, isLoadingScroll }));
  };
  const setTotalBrand = (totalBrand) => {
    setState((val) => ({ ...val, totalBrand }));
  };
  const setSelectBrand = (selectBrand) => {
    setState((val) => ({ ...val, selectBrand }));
  };

  const initState = {
    brands: [],
    cursor: 0,
    search: "",
    isLoading: true,
    isLoadingScroll: false,
    totalBrand: 0,
    selectBrand: [],
    setBrand,
    setIsLoading,
    setSearch,
    setCursor,
    setIsLoadingScroll,
    setTotalBrand,
    setSelectBrand,
  };

  const [state, setState] = useState(initState);

  return (
    <BrandContext.Provider value={state}>{children}</BrandContext.Provider>
  );
};

export { BrandContext, BrandContextProvider };
