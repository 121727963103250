import { lazy } from "react";
const ProductDine = lazy(() => import("~/pages/Property/Dine/Product"));
const BrandDine = lazy(() => import("~/pages/Property/Dine/Brand"));

const TenantTypeHome = lazy(() => import("~/pages/Property/TenantType/Home"));
const TenantType = lazy(() => import("~/pages/Property/TenantType"));

const DineProductDetail = lazy(() =>
  import("~/pages/Property/Dine/Product/Detail")
);
const DineBrandDetail = lazy(() =>
  import("~/pages/Property/Dine/Brand/Detail")
);

const dineRoute = {
  path: "dine",
  element: <TenantType type={"dine"} />,
  children: [
    {
      path: "",
      element: <TenantTypeHome type={"dine"} />,
      children: [
        {
          index: true,
          element: <ProductDine type={"dine"} />,
        },
        {
          path: "product",
          element: <ProductDine type={"dine"} />,
        },
        {
          path: "brand",
          element: <BrandDine />,
        },
      ],
    },
    {
      path: "product/:productName",
      element: <DineProductDetail />,
    },
    {
      path: "brand/:brandName",
      element: <DineBrandDetail />,
    },
  ],
};

export default dineRoute;
