import { useState, createContext, useEffect } from "react";

const AuthContext = createContext({
  lastLogin: {},
  setLastLogin: () => {},
  handleRefresh: () => {},
  resetAuth: () => {},
});

const AuthContextProvider = ({ children }) => {
  const setLastLogin = (lastLogin) => {
    setState((val) => ({ ...val, lastLogin }));
    localStorage.setItem("lastLogin", JSON.stringify(lastLogin));
  };

  const resetAuth = () => {
    localStorage.removeItem("lastLogin");
    setState(initState);
  };

  const handleRefresh = () => {
    const lastLogin = localStorage.getItem("lastLogin");
    if (lastLogin) {
      setLastLogin(JSON.parse(lastLogin));
    } else {
      resetAuth();
    }
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const initState = { lastLogin: {}, setLastLogin, handleRefresh, resetAuth };

  const [state, setState] = useState(initState);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthContextProvider };
