import { useLocation, Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const { pathname } = useLocation();
  const lastLogin = localStorage.getItem("lastLogin");

  if (lastLogin) {
    return children;
  } else if (pathname.includes("register") || pathname.includes("login")) {
    return children;
  } else {
    return <Navigate to="/auth/login" />;
  }
};

export default ProtectedRoute;
