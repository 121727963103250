import { lazy } from "react";
const Bag = lazy(() => import("~/pages/Bag"));
const ProtectedRoute = lazy(() => import("~/components/ProtectedRoute"));

const bagRoute = {
  path: "bag",
  element: (
    <ProtectedRoute>
      <Bag />
    </ProtectedRoute>
  ),
};

export default bagRoute;
