import { lazy } from "react";
const ProtectedRoute = lazy(() => import("~/components/ProtectedRoute"));
const Voucers = lazy(() => import("~/pages/Property/Voucers"));
const DetailVoucer = lazy(() => import("~/pages/Property/Voucers/Detail"));

const voucerRoute = [
  {
    path: "vouchers",
    element: (
      <ProtectedRoute>
        <Voucers />
      </ProtectedRoute>
    ),
  },
  {
    path: "vouchers/:voucerId/detail",
    element: (
      <ProtectedRoute>
        <DetailVoucer />
      </ProtectedRoute>
    ),
  },
];

export default voucerRoute;
