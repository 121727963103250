import { lazy } from "react";
const Search = lazy(() => import("~/pages/Search"));
// const EditorialHome = lazy(() => import("~/pages/Editorial/home"));
// const Detail = lazy(() => import("~/pages/Editorial/Detail/Detail.jsx"));
// const ShopProductDetail = lazy(() =>
//   import("~/pages/Property/Shop/Product/Detail")
// );
// const ProductShop = lazy(() => import("~/pages/Property/Shop/Product"));
// const TenantTypeHome = lazy(() => import("~/pages/Property/TenantType/Home"));

const search = {
  path: "search/:keyword",
  element: <Search />,
};

export default search;
