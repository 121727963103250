import { lazy } from "react";
const OrderType = lazy(() => import("~/pages/Checkout/OrderType"));
const Pickup = lazy(() => import("~/pages/Checkout/OrderType/Pickup"));
const SendToTable = lazy(() => import("~/pages/Checkout/OrderType/SendTable"));
const SendToRoom = lazy(() => import("~/pages/Checkout/OrderType/SendRoom"));
const Delivery = lazy(() => import("~/pages/Checkout/OrderType/Delivery"));
const JoinDelivery = lazy(() =>
  import("~/pages/Checkout/OrderType/Delivery/JoinDelevery")
);
const ProtectedRoute = lazy(() => import("~/components/ProtectedRoute"));

const checkoutRoute = [
  {
    path: "order-type",
    element: (
      <ProtectedRoute>
        <OrderType />
      </ProtectedRoute>
    ),
  },
  {
    path: "order-type/pickup",
    element: (
      <ProtectedRoute>
        <Pickup />
      </ProtectedRoute>
    ),
  },
  {
    path: "order-type/send-table",
    element: (
      <ProtectedRoute>
        <SendToTable />
      </ProtectedRoute>
    ),
  },
  {
    path: "order-type/send-room",
    element: (
      <ProtectedRoute>
        <SendToRoom />
      </ProtectedRoute>
    ),
  },
  {
    path: "order-type/delivery",
    element: (
      <ProtectedRoute>
        <Delivery />
      </ProtectedRoute>
    ),
  },
  {
    path: "order-type/join-delivery",
    element: (
      <ProtectedRoute>
        <JoinDelivery />
      </ProtectedRoute>
    ),
  },
];

export default checkoutRoute;
