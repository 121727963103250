import { lazy } from "react";

const ProductShop = lazy(() => import("~/pages/Property/Shop/Product"));
const BrandShop = lazy(() => import("~/pages/Property/Shop/Brand"));

const TenantTypeHome = lazy(() => import("~/pages/Property/TenantType/Home"));
const TenantType = lazy(() => import("~/pages/Property/TenantType"));

const ShopProductDetail = lazy(() =>
  import("~/pages/Property/Shop/Product/Detail")
);
const ShopBrandDetail = lazy(() =>
  import("~/pages/Property/Shop/Brand/Detail")
);

const shopRoute = {
  path: "shop",
  element: <TenantType type={"shop"} />,
  children: [
    {
      path: "",
      element: <TenantTypeHome type={"shop"} />,
      children: [
        {
          index: true,
          element: <ProductShop type={"shop"} />,
        },
        {
          path: "product",
          element: <ProductShop type={"shop"} />,
        },
        {
          path: "brand",
          element: <BrandShop />,
        },
      ],
    },
    {
      path: "product/:productName",
      element: <ShopProductDetail />,
    },
    {
      path: "brand/:brandName",
      element: <ShopBrandDetail />,
    },
  ],
};

export default shopRoute;
