// import ApiService from "./ApiService";
import xhrInstance from "~/services/config";

export default class TransactionService {
  static checkOrders = (value) =>
    xhrInstance.post(`/transaction/check_orders`, value);
  // static getOrders = async (params) => {
  //   const instance = await ApiService(true);
  //   let num, cursor, progress, delivery_st, sort, keyword;
  //   if (params?.num && params?.num !== "") {
  //     num = `?num=${params.num}`;
  //   } else {
  //     num = `?`;
  //   }
  //   if (params?.cursor && params?.cursor !== "") {
  //     cursor = `&cursor=${params.cursor}`;
  //   }
  //   if (params?.progress && params?.progress !== "") {
  //     progress = `&progress=${params.progress}`;
  //   }
  //   if (params?.sort && params?.sort !== "") {
  //     sort = `&sort=${params.sort}`;
  //   }
  //   if (params?.keyword && params?.keyword !== "") {
  //     keyword = `&keyword=${params.keyword}`;
  //   }
  //   return instance.get(
  //     `/transaction/order${num}${cursor || ""}${progress || ""}${
  //       delivery_st || ""
  //     }${sort || ""}${keyword || ""}`
  //   );
  // };
  // static getOrderDetail = async (orderID) => {
  //   const instance = await ApiService(true);
  //   return instance.get(`/transaction/order/${orderID}`);
  // };
  // static getOrderLog = async (orderID) => {
  //   const instance = await ApiService(true);
  //   return instance.get(`/transaction/order/logs/${orderID}`);
  // };
  // // New
  static getCartActive = () => xhrInstance.get(`/transaction/cart/active`);
  static postTransactionCart = (cart) =>
    xhrInstance.post(`/transaction/cart`, cart);

  static patchTransactionCart = (id, params) =>
    xhrInstance.patch(`/transaction/cart/${id}`, params);

  static patchTransactionCartOrder = (trxID, orderID, params) =>
    xhrInstance.patch(`/transaction/cart/order/${trxID}/${orderID}`, params);
  // static getTransactionDeliveryRates = async (delivery_package_id) => {
  //   const instance = await ApiService(true);
  //   return instance.get(
  //     `/transaction/cart/delivery_rates/${delivery_package_id}`
  //   );
  // };
  static getRateDeliveryGroups = () =>
    xhrInstance.get(`/transaction/cart/delivery_group_rates`);
  static putTransactionJoinDelivery = (params) =>
    xhrInstance.put(`/transaction/cart/join_delivery`, params);
  static postTransactionOneClick = () =>
    xhrInstance.post(`/transaction/oneclick`);

  // static getOrderHistorySummary = async (params) => {
  //   let keyword;
  //   if (params.keyword !== "") {
  //     keyword = `?keyword=${params.keyword}`;
  //   }
  //   const instance = await ApiService(true);
  //   return instance.get(`/transaction/order/history_summary${keyword || ""}`);
  // };

  static getOrderHistoryAll = (params) => {
    // const instance = await xhrInstance(true);
    let num, cursor, progress, delivery_st, sort, keyword;
    if (params?.num && params?.num !== "") {
      num = `?num=${params.num}`;
    } else {
      num = `?`;
    }
    if (params?.cursor && params?.cursor !== "") {
      cursor = `&cursor=${params.cursor}`;
    }
    if (params?.progress && params?.progress !== "") {
      progress = `&progress=${params.progress}`;
    }
    if (params?.sort && params?.sort !== "") {
      sort = `&sort=${params.sort}`;
    }
    if (params?.keyword && params?.keyword !== "") {
      keyword = `&keyword=${params.keyword}`;
    }

    return xhrInstance.get(
      `/transaction/order/history${num}${cursor || ""}${progress || ""}${
        delivery_st || ""
      }${sort || ""}${keyword || ""}`
    );
  };
  static getOrderDetail = (orderID) => {
    // const instance = await ApiService(true);
    return xhrInstance.get(`/transaction/${orderID}`);
  };
  static getOrderDetailLogs = (orderID) => {
    // const instance = await ApiService(true);
    return xhrInstance.get(`/transaction/order/logs/${orderID}`);
  };

  // static getOrderHistory = async (params) => {
  //   let num, cursor, keyword, progress;
  //   num = `?num=20`;
  //   if (params.num && params.num !== "") {
  //     num = `?num=${params.num}`;
  //   }
  //   if (params.cursor !== "") {
  //     cursor = `&cursor=${params.cursor}`;
  //   }
  //   if (params.progress !== "") {
  //     progress = `&progress=${params.progress}`;
  //   }
  //   if (params.keyword !== "") {
  //     keyword = `&keyword=${params.keyword}`;
  //   }
  //   const instance = await ApiService(true);
  //   return instance.get(
  //     `/transaction/order/history${num}${cursor || ""}${progress || ""}${
  //       keyword || ""
  //     }`
  //   );
  // };

  // static getTransactionByOrderID = async (paidSt, orderID) => {
  //   let paid;
  //   paid = `&paid=${paidSt}`;
  //   const instance = await ApiService(true);
  //   return instance.get(`/transaction?order_id=${orderID}${paid || ""}`);
  // };
  static getTransactionDeliveryGroup = (orderID) => {
    // const instance = await ApiService(true);
    return xhrInstance.get(`/transaction/delivery_group/${orderID}`);
  };
}
