import { lazy } from "react";

const Home = lazy(() => import("~/pages/Home"));

const home = {
  index: true,
  element: <Home />,
};

export default home;
