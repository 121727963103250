import { useContext } from "react";
// import { AppContext } from "~/stores/contexts";
import { HeaderContext } from "~/stores/contexts/header";

const Alert = () => {
  const { alert } = useContext(HeaderContext);

  return (
    alert?.total.length > 0 && (
      <div className="fixed top-16 left-0 z-50 w-full">
        <div className="flex items-center flex-col mx-5">
          {alert?.total.map((item, index) => (
            <div
              key={index}
              className={
                "py-2 px-4 rounded text-white text-sm mb-2 w-fit text-center " +
                (() => {
                  if (alert?.type === "success") {
                    return "bg-green-400";
                  } else if (alert?.type === "danger") {
                    return "bg-red-400";
                  } else {
                    return "bg-orange-400";
                  }
                })()
              }
            >
              {alert?.msg}
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default Alert;
