import { lazy } from "react";
const ProtectedRoute = lazy(() => import("~/components/ProtectedRoute"));
const Wishlist = lazy(() => import("~/pages/Wishlist"));

const wishlist = {
  path: "wishlist",
  element: (
    <ProtectedRoute>
      <Wishlist />
    </ProtectedRoute>
  ),
};

export default wishlist;
