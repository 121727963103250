import { lazy } from "react";
const Menu = lazy(() => import("~/pages/Auth/Menu"));
const Profile = lazy(() => import("~/pages/Auth/Menu/Profile"));
const EditProfile = lazy(() => import("~/pages/Auth/Menu/Profile/Edit"));
const CheckPassword = lazy(() => import("~/pages/Auth/Menu/CheckPassword"));
const ChangePassword = lazy(() => import("~/pages/Auth/Menu/ChangePassword"));
const Transaction = lazy(() => import("~/pages/Auth/Menu/Transaction"));
const MyVoucher = lazy(() => import("~/pages/Auth/Menu/MyVoucher"));
const TransactionDetail = lazy(() =>
  import("~/pages/Auth/Menu/Transaction/TransactionDetail")
);
const TransactionDetailStatus = lazy(() =>
  import("~/pages/Auth/Menu/Transaction/TransactionDetail/DetailStatus")
);

const menu = [
  {
    path: "menu",
    element: <Menu />,
  },
  {
    path: "profile",
    element: <Profile />,
  },
  {
    path: "editProfile/:id",
    element: <EditProfile />,
  },
  {
    path: "check-password",
    element: <CheckPassword />,
  },
  {
    path: "change-password",
    element: <ChangePassword />,
  },
  {
    path: "transaction",
    element: <Transaction />,
  },
  {
    path: "voucher",
    element: <MyVoucher />,
  },
  {
    path: "detailStatus",
    element: <TransactionDetailStatus />,
  },
  {
    path: "transactionDetail/:id",
    element: <TransactionDetail />,
  },
];

export default menu;
