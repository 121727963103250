import { lazy } from "react";
const Editorial = lazy(() => import("~/pages/Editorial"));
const EditorialHome = lazy(() => import("~/pages/Editorial/home"));
const Detail = lazy(() => import("~/pages/Editorial/Detail/Detail.jsx"));
const ShopProductDetail = lazy(() =>
  import("~/pages/Property/Shop/Product/Detail")
);
const editorial = {
  path: "editorial",
  element: <Editorial />,
  children: [
    {
      index: true,
      element: <EditorialHome />,
    },
    {
      path: "/editorial/:id",
      element: <Detail />,
    },
    {
      path: "/editorial/:name/detail/:productName",
      element: <ShopProductDetail />,
    },
  ],
};

export default editorial;
