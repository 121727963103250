import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
  timeout: 60000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-App-Source": "shop-malltogo",
    "Access-Control-Allow-Origin": "*",
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const lastLogin = localStorage.getItem("lastLogin");
    if (lastLogin) {
      const lastLoginParse = JSON.parse(lastLogin);
      config.headers.Authorization = "Bearer " + lastLoginParse?.token;
    } else {
      delete config.headers.Authorization;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
