// import ApiService from "./ApiService";
import xhrInstance from "~/services/config";
export default class PropertyService {
  static getList = (params) => {
    // const instance = await ApiService();
    let num, cursor, type, keyword;

    if (params?.num && params?.num !== "") {
      num = `?num=${params.num}`;
    } else {
      num = `?`;
    }

    if (params?.cursor && params?.cursor !== "") {
      cursor = `&cursor=${params.cursor}`;
    }

    if (params?.type && params?.type !== "") {
      type = `&type=${params.type}`;
    }

    if (params?.keyword && params?.keyword !== "") {
      keyword = `&keyword=${params.keyword}`;
    }

    return xhrInstance.get(
      `/property${num}${cursor || ""}${type || ""}${keyword || ""}`
    );
  };

  static getDetail = async (id) => {
    // const xhrInstance = await ApiService();
    return xhrInstance.get(`/property/${id}`);
  };
  static getAll = async (id) => {
    // const xhrInstance = await ApiService();
    return xhrInstance.get(`/property`);
  };

  static putCounter = async (id, params) => {
    // const xhrInstance = await ApiService();
    return xhrInstance.put(`/property/${id}`, params);
  };
}
