import combineComponents from "~/stores/contexts/combined";
import { HeaderContextProvider } from "~/stores/contexts/header";
import { AuthContextProvider } from "~/stores/contexts/auth";
import { BagContextProvider } from "~/stores/contexts/bag";
import { ProductContextProvider } from "~/stores/contexts/product";
import { BrandContextProvider } from "~/stores/contexts/brand";
import { CategoryContextProvider } from "~/stores/contexts/category";

const providers = [
  HeaderContextProvider,
  AuthContextProvider,
  BagContextProvider,
  ProductContextProvider,
  BrandContextProvider,
  CategoryContextProvider,
];

export const AppContextProvider = combineComponents(...providers);
