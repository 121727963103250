import { useEffect, useContext, useLayoutEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "~/stores/contexts/auth";
import { HeaderContext } from "~/stores/contexts/header";

const ErrorBoundary = ({ children }) => {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    const canControlScrollRestoration = "scrollRestoration" in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  const navigate = useNavigate();
  const { resetAuth } = useContext(AuthContext);
  const { alert } = useContext(HeaderContext);
  async function getError(event) {
    if (Object.keys(event?.reason).length > 0) {
      const { response } = event?.reason;
      if (
        response.status === 401 &&
        response?.data?.message === "UnAuthorized"
      ) {
        resetAuth();
        navigate("/", { replace: true });
        alert.setActive("warning", "Your session expired, please login again");
      }
    }
  }

  useEffect(() => {
    window.addEventListener("unhandledrejection", getError);
    return () => {
      window.removeEventListener("unhandledrejection", getError);
    };
  }, []);
  return children;
};

export default ErrorBoundary;
