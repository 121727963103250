import { useState, createContext } from "react";

const HeaderContext = createContext({
  header: {
    sideBarAccount: false,
    menuBar: false,
    setMenuBar: () => {},
    setSideBarAccount: () => {},
  },
  alert: {
    active: false,
    setActive: () => {},
    addTotal: () => {},
    removeTotal: () => {},
    total: [],
    msg: "Alert",
    type: "success",
  },
});

const HeaderContextProvider = ({ children }) => {
  const setSideBarAccount = (sideBarAccount) => {
    setState((val) => ({ ...val, header: { ...val.header, sideBarAccount } }));
  };

  const setMenuBar = (menuBar) => {
    setState((val) => ({ ...val, header: { ...val.header, menuBar } }));
  };

  const setActive = (type, msg, time) => {
    setState((val) => ({ ...val, alert: { ...val.alert, type, msg } }));
    addTotal();
    setTimeout(
      () => {
        removeTotal();
      },
      time ? time : 2000
    );
  };

  const addTotal = () => {
    setState((val) => ({
      ...val,
      alert: { ...val.alert, total: [...val.alert.total, 1] },
    }));
  };

  const removeTotal = () => {
    setState((val) => ({
      ...val,
      alert: { ...val.alert, total: val.alert.total.slice(1) },
    }));
  };

  const initState = {
    header: {
      sideBarAccount: false,
      menuBar: false,
      setSideBarAccount,
      setMenuBar,
    },
    alert: {
      active: false,
      setActive,
      addTotal,
      removeTotal,
      total: [],
      msg: "Alert",
      type: "success",
    },
  };

  const [state, setState] = useState(initState);

  return (
    <HeaderContext.Provider value={state}>{children}</HeaderContext.Provider>
  );
};

export { HeaderContext, HeaderContextProvider };
