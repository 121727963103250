import { lazy } from "react";

const ForgotPassword = lazy(() => import("~/pages/ForgotPassword"));

const forgotPassword = {
  path: "forgot-password",
  element: <ForgotPassword />,
};

export default forgotPassword;
