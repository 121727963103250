import { lazy } from "react";
import ProtectedRoute from "~/components/ProtectedRoute";
const Curated = lazy(() => import("~/pages/OtherProduct/Curated"));
const RecentlyView = lazy(() => import("~/pages/OtherProduct/RecentlyView"));

const otherProduct = [
  {
    path: "curated/:tenantType",
    element: <Curated />,
  },
  {
    path: "recently-view",
    element: (
      <ProtectedRoute>
        <RecentlyView />
      </ProtectedRoute>
    ),
  },
];

export default otherProduct;
