import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  // createTransform,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
// import CryptoJS from "crypto-js";
import rootReducer from "~/stores/rootReducer";

// const encrypt = createTransform(
//   (inboundState, key) => {
//     if (!inboundState) return inboundState;
//     const cryptedText = CryptoJS.AES.encrypt(
//       JSON.stringify(inboundState),
//       process.env.REACT_APP_SECRET_KEY
//     );

//     return cryptedText.toString();
//   },
//   (outboundState, key) => {
//     if (!outboundState) return outboundState;
//     const bytes = CryptoJS.AES.decrypt(
//       outboundState,
//       process.env.REACT_APP_SECRET_KEY
//     );
//     const decrypted = bytes.toString(CryptoJS.enc.Utf8);

//     return JSON.parse(decrypted);
//   }
// );

const persistConfig = {
  key: "root",
  storage: storage,
  timeout: null,
  whitelist: ["property"],
  // transforms: [encrypt],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_ENV === "DEV",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
