import { createSlice } from "@reduxjs/toolkit";
import { getProperties } from "~/stores/slices/property/actions";

const initialState = {
  properties: [],
  isLoading: false,
};

export const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setProperties: (state, payload) => {
      state.properties = [1, 2, 3];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProperties.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProperties.fulfilled, (state, action) => {
        state.properties = action.payload;
        state.isLoading = false;
      });
  },
});

export const { setProperties } = propertySlice.actions;

export default propertySlice.reducer;
