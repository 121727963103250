import { useState, createContext } from "react";

const ProductContext = createContext({
  products: [],
  cursor: 0,
  search: "",
  isLoading: true,
  isLoadingScroll: false,
  totalProduct: 0,
  sortProduct: "",
  shopFront: "",
  productId: null,
  setIsLoading: () => {},
  setProduct: () => {},
  setSearch: () => {},
  setCursor: () => {},
  setIsLoadingScroll: () => {},
  setTotalProduct: () => {},
  setSortProduct: () => {},
  setShopFront: () => {},
  setProductId: () => {},
  resetRestorationScroll: () => {},
});

const ProductContextProvider = ({ children }) => {
  const setProduct = (products) => {
    setState((val) => ({ ...val, products }));
  };
  const setIsLoading = (isLoading) => {
    setState((val) => ({ ...val, isLoading }));
  };
  const setSearch = (search) => {
    setState((val) => ({ ...val, search }));
  };
  const setCursor = (cursor) => {
    setState((val) => ({ ...val, cursor }));
  };
  const setIsLoadingScroll = (isLoadingScroll) => {
    setState((val) => ({ ...val, isLoadingScroll }));
  };
  const setTotalProduct = (totalProduct) => {
    setState((val) => ({ ...val, totalProduct }));
  };

  const setSortProduct = (sortProduct) => {
    setState((val) => ({ ...val, sortProduct }));
  };

  const setShopFront = (shopFront) => {
    setState((val) => ({ ...val, shopFront }));
  };

  const setProductId = (productId) => {
    setState((val) => ({ ...val, productId }));
  };

  const resetRestorationScroll = () => {
    setState((val) => ({ ...val, productId: null, cursor: 0 }));
  };

  const initState = {
    products: [],
    cursor: 0,
    search: "",
    isLoading: true,
    isLoadingScroll: false,
    totalProduct: 0,
    sortProduct: "",
    shopFront: "",
    productId: null,
    setProduct,
    setIsLoading,
    setSearch,
    setCursor,
    setIsLoadingScroll,
    setTotalProduct,
    setSortProduct,
    setShopFront,
    setProductId,
    resetRestorationScroll,
  };

  const [state, setState] = useState(initState);

  return (
    <ProductContext.Provider value={state}>{children}</ProductContext.Provider>
  );
};

export { ProductContext, ProductContextProvider };
