import { lazy } from "react";
const PaymentMethod = lazy(() => import("~/pages/Checkout/PaymentMethod"));
const PaymentResume = lazy(() => import("~/pages/Checkout/PaymentResume"));
const ProtectedRoute = lazy(() => import("~/components/ProtectedRoute"));

const paymentRoute = [
  {
    path: "payment",
    element: (
      <ProtectedRoute>
        <PaymentMethod />
      </ProtectedRoute>
    ),
  },
  {
    path: "payment-resume",
    element: (
      <ProtectedRoute>
        <PaymentResume />
      </ProtectedRoute>
    ),
  },
];

export default paymentRoute;
