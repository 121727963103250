import { lazy } from "react";
const Address = lazy(() => import("~/pages/Auth/Address"));
const CreateAddress = lazy(() => import("~/pages/Auth/Address/Create"));
const EditAddress = lazy(() => import("~/pages/Auth/Address/Edit"));

const menu = [
  {
    path: "address",
    element: <Address />,
  },
  {
    path: "delivery-address",
    element: <Address />,
  },
  {
    path: "createAddress",
    element: <CreateAddress />,
  },
  {
    path: "create-delivery-address",
    element: <CreateAddress />,
  },
  {
    path: "editAddress/:id",
    element: <EditAddress />,
  },
];

export default menu;
