import { lazy } from "react";

const EnterMail = lazy(() => import("~/pages/Auth/Register/EnterMail"));
const EnterBio = lazy(() => import("~/pages/Auth/Register/EnterBio"));

const register = [
  {
    path: "register/mail",
    element: <EnterMail />,
  },
  {
    path: "register/bio",
    element: <EnterBio />,
  },
];

export default register;
