import { lazy } from "react";

const Login = lazy(() => import("~/pages/Auth/Login"));

const login = [
  {
    index: true,
    element: <Login />,
  },
  {
    path: "login",
    element: <Login />,
  },
];

export default login;
