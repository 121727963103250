import { lazy } from "react";
import bagRoute from "~/routes/property/bag";
import dineRoute from "~/routes/property/dine";
import shopRoute from "~/routes/property/shop";
import checkoutRoute from "~/routes/property/checkout";
import paymentRoute from "~/routes/property/payment";
import voucerRouter from "~/routes/property/voucers";

const Property = lazy(() => import("~/pages/Property"));
const PropertyHome = lazy(() => import("~/pages/Property/Home"));

const property = {
  path: "property/:name",
  element: (
    <>
      <Property />
    </>
  ),
  children: [
    {
      index: true,
      element: <PropertyHome />,
    },
    dineRoute,
    shopRoute,
    bagRoute,
    ...checkoutRoute,
    ...paymentRoute,
    ...voucerRouter,
  ],
};

export default property;
