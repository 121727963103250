import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuBar: false,
  sideBarAccount: false,
};

export const propertySlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setMenuBar: (state, payload) => {
      state.menuBar = payload;
    },
    setSideBarAccount: (state, payload) => {
      state.sideBarAccount = payload;
    },
  },
});

export const { setMenuBar, setSideBarAccount } = propertySlice.actions;

export default propertySlice.reducer;
