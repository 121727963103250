// import { Player } from "@lottiefiles/react-lottie-player";
// import AnimationData from "~/assets/jsons/loading_screen.json";

const LoadingScreen = () => {
  return (
    <div>
      <div className="flex justify-center h-screen">
        <div className="flex my-auto">
          {/* <Player
            src={AnimationData}
            loop
            autoplay
            speed={1}
            height={300}
            width={300}
          /> */}
          Loading...
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
