import { useState, createContext } from "react";

const BagContext = createContext({
  bagItems: {},
  bagItemTotal: 0,
  bagProperty: {},
  setBagItem: () => {},
});

const BagContextProvider = ({ children }) => {
  const toLocalStorage = (data) => {
    localStorage.setItem("bagItemTotal", data);
  };
  const setBagItem = (data) => {
    if (data === "") {
      setState(initState);
      toLocalStorage(0);
    } else {
      const [fistEl] = data?.orders;
      setState((val) => ({
        ...val,
        bagItemTotal: data?.total_item,
        bagItems: data,
        bagProperty: fistEl?.tenant?.property,
      }));
      toLocalStorage(data?.total_item);
    }
  };

  const initState = {
    bagItems: {},
    bagItemTotal: 0,
    bagProperty: {},
    setBagItem,
  };

  const [state, setState] = useState(initState);

  return <BagContext.Provider value={state}>{children}</BagContext.Provider>;
};

export { BagContext, BagContextProvider };
