import { useEffect, useContext } from "react";
import { RouterProvider } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BagContext } from "~/stores/contexts/bag";
import { AuthContext } from "~/stores/contexts/auth";
import routes from "~/routes";
import { getProperties } from "~/stores/slices/property/actions";
import Alert from "~/components/Alert";
import TransactionService from "~/services/TransactionService";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-170080594-2"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  const dispatch = useDispatch();
  const { setBagItem } = useContext(BagContext);
  const { lastLogin } = useContext(AuthContext);

  const getBagItem = async () => {
    if (Object.keys(lastLogin).length > 0) {
      const res = await TransactionService.getCartActive();
      setBagItem(res?.data);
    }
  };

  useEffect(() => {
    getBagItem();
  }, [lastLogin]);

  useEffect(() => {
    dispatch(getProperties());
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="relative">
      <RouterProvider router={routes} />
      <Alert />
    </div>
  );
};

export default App;
