import { lazy } from "react";

const Promo = lazy(() => import("~/pages/Promo"));
const PromoHome = lazy(() => import("~/pages/Promo/home"));
const Detail = lazy(() => import("~/pages/Promo/Detail"));

const promo = {
  path: "promo",
  element: <Promo />,
  children: [
    {
      index: true,
      element: <PromoHome />,
    },
    {
      path: ":title",
      element: <Detail />,
    },
  ],
};

export default promo;
