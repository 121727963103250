import { useState, createContext } from "react";

const CategoryContext = createContext({
  activeTab: "product",
  categories: [],
  isLoading: false,
  filterBy: "",
  setCategory: () => {},
  setIsLoading: () => {},
  setFilterBy: () => {},
  setActiveTab: () => {},
});

const CategoryContextProvider = ({ children }) => {
  const setCategory = (categories) => {
    setState((val) => ({ ...val, categories }));
  };
  const setIsLoading = (isLoading) => {
    setState((val) => ({ ...val, isLoading }));
  };
  const setFilterBy = (filterBy) => {
    setState((val) => ({ ...val, filterBy }));
  };

  const setActiveTab = (activeTab) => {
    setState((val) => ({ ...val, activeTab }));
  };

  const initState = {
    categories: [],
    isLoading: false,
    filterBy: "",
    activeTab: "product",
    setCategory,
    setIsLoading,
    setFilterBy,
    setActiveTab,
  };

  const [state, setState] = useState(initState);

  return (
    <CategoryContext.Provider value={state}>
      {children}
    </CategoryContext.Provider>
  );
};

export { CategoryContext, CategoryContextProvider };
