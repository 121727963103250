import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "~/components/ErrorPage";
import ErrorBoundary from "~/components/ErrorBoundary";
import homeRouter from "~/routes/home";
import editorialRouter from "~/routes/editorial";
import wishlist from "~/routes/wishlist";
import search from "~/routes/search";
import promoRouter from "~/routes/promo";
import propertyRouter from "~/routes/property";
import authRouter from "~/routes/auth";
import forgotPassRouter from "~/routes/forgotPassword";
import passRouter from "~/routes/password";
import otherProductRouter from "~/routes/otherProduct";

const BaseLayout = lazy(() => import("~/components/BaseLayout"));
const ProtectedRoute = lazy(() => import("~/components/ProtectedRoute"));

const routes = createBrowserRouter([
  {
    path: "/",
    element: (
      <ErrorBoundary>
        <BaseLayout />
      </ErrorBoundary>
    ),
    errorElement: <ErrorPage />,
    children: [
      homeRouter,
      editorialRouter,
      promoRouter,
      propertyRouter,
      wishlist,
      search,
      ...otherProductRouter,
    ],
  },
  {
    path: "/auth",
    element: (
      <ErrorBoundary>
        <ProtectedRoute>
          <BaseLayout />
        </ProtectedRoute>
      </ErrorBoundary>
    ),
    errorElement: <ErrorPage />,
    children: [...authRouter],
  },
  {
    path: "/",
    element: (
      <ErrorBoundary>
        <BaseLayout />
      </ErrorBoundary>
    ),
    errorElement: <ErrorPage />,
    children: [forgotPassRouter],
  },
  {
    path: "/account",
    element: (
      <ErrorBoundary>
        <BaseLayout />
      </ErrorBoundary>
    ),
    errorElement: <ErrorPage />,
    children: [...passRouter],
  },
]);
// console.log(routes);

export default routes;
