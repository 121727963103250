import { useRouteError, isRouteErrorResponse, Link } from "react-router-dom";
import notfoundImg from "~/assets/images/error/img-pagenotfound.png";
import { Helmet } from "react-helmet";

function ErrorPage() {
  const error = useRouteError();
  console.log(error);

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <>
          <Helmet>
            <meta name="googlebot" content="noindex,nofollow" />
          </Helmet>
          <div className="flex justify-center h-screen">
            <div className="flex flex-col my-auto">
              <img
                src={notfoundImg}
                alt="not_found_page"
                className="w-80 md:w-96"
              />
              <div className="flex flex-col justify-center pt-4 space-y-6">
                <h1 className="text-center text-orange-400 cs-text-title-30">
                  Page not found
                </h1>
                <div className="flex justify-center">
                  <Link
                    to="/"
                    className="py-3 px-6 bg-orange-300 border text-center text-white font-title"
                  >
                    Back to home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    if (error.status === 401) {
      return <div>You aren't authorized to see this</div>;
    }

    if (error.status === 503) {
      return <div>Looks like our API is down</div>;
    }

    if (error.status === 418) {
      return <div>🫖</div>;
    }
  }

  return (
    <>
      <Helmet>
        <meta name="googlebot" content="noindex,nofollow" />
      </Helmet>
      <div className="flex justify-center h-screen">
        <div className="flex flex-col my-auto">
          <img
            src={notfoundImg}
            alt="not_found_page"
            className="w-80 md:w-96"
          />
        </div>
      </div>
    </>
  );
}

export default ErrorPage;
